<template>
  <div>
    <b-card class="page-card" body-class="px-0">
      <div class="row">
        <div class="col-sm-12">
          <div class="table-responsive">
            <div v-if="fetchingFIs">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="10"
                ></content-placeholders-text>
              </content-placeholders>
            </div>
            <div v-else>
              <b-table
                hover
                striped
                responsive
                class="text-nowrap"
                :fields="tableFields"
                :items="tableData"
                show-empty
              >
                <template #cell()="data">
                  <div :class="determineCellClasses(data.field)">
                    {{ data.item[data.field.key] }}
                  </div>
                </template>
                <template v-slot:empty="scope">
                  <div class="text-center p-2">
                    <h6>No matching institutions found.</h6>
                  </div>
                </template>
                <template v-slot:table-colgroup="scope">
                  <col v-for="field in scope.fields" :key="field.key" />
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>

      <div>
        <br />
        <div class="row">
          <div class="col-sm-6 pb-0">
            <div v-if="fetchingPriceData || fetchingSubscription">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="5"
                ></content-placeholders-text>
              </content-placeholders>
            </div>
            <b-card class="pb-0 mb-0" v-else>
              <v-wait for="updatingPriceDetails">
                <template slot="waiting">
                  <content-placeholders-heading />
                  <content-placeholders-text
                    :lines="7"
                  ></content-placeholders-text>
                </template>
                <div
                  class="purchase-card alert alert-info"
                  v-if="totalPriceInDollars"
                >
                  <b-row class="pb-0">
                    <b-col cols="2">
                      <span class="title-icon">
                        <i class="fa fa-download fa-3x"></i>
                      </span>
                    </b-col>
                    <b-col cols="5" class="pl-0">
                      <div>
                        <h2 class="mb-0">
                          <b>${{ totalPriceInDollars | numberFormat }}</b>
                          <small>
                            <sup>{{ totalPriceInCents }}</sup>
                          </small>
                        </h2>
                      </div>
                      <div>{{ purchaseHintText }}</div>
                    </b-col>
                    <b-col cols="5" style="color: white;">
                      <button
                        v-b-modal
                        class="btn btn-success"
                        @click="purchaseFIData()"
                        :disabled="!enablePurchase"
                      >
                        {{ purchaseBtnText }}
                      </button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2"></b-col>
                    <b-col cols="8">
                      <b-row class="price-box">
                        <table class="table-borderless">
                          <thead>
                            <tr>
                              <th class="price-info">Price Details</th>
                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody class="pt-1">
                            <tr>
                              <td>Number of FIs</td>
                              <td class="text-right">{{ fiCount }}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Data Elements</td>
                              <td class="text-right">{{ elementCount }}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Cost / FI / Element</td>
                              <td class="text-right">
                                ${{
                                  parseFloat(costPerFIPerElement).toFixed(2)
                                }}
                              </td>
                              <td></td>
                            </tr>
                            <tr v-if="tokens !== 0">
                              <td>Tokens Used</td>
                              <td class="text-right">
                                {{ tokensBeingUsed() | numberFormat }}
                              </td>
                              <td class="text-no-wrap">
                                of {{ tokens | numberFormat }} total
                              </td>
                            </tr>
                            <tr class="strong font-weight-bold">
                              <td class="pt-2">Total Price</td>
                              <td class="text-right pt-2">
                                ${{ totalPrice.toFixed(2) }}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </v-wait>
            </b-card>
            <div v-if="!apiKeyLoaded">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="5"
                ></content-placeholders-text>
              </content-placeholders>
            </div>
            <div v-else>
              <b-card v-if="canShowApiSection">
                <div class="purchase-card alert alert-info">
                  <b-row class="pb-2">
                    <b-col cols="2">
                      <span class="title-icon">
                        <i class="fa fa-gears fa-4x"></i>
                      </span>
                    </b-col>
                    <b-col cols="10" class="pl-0">
                      <div>
                        <h2 class="mb-0">
                          <b>API Key</b>
                        </h2>
                      </div>
                      <div>For Development License Holders</div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="2"></b-col>

                    <b-col cols="10">
                      <b-row class="price-box">
                        <table class="table-borderless full-width ">
                          <thead>
                            <tr>
                              <th width="30%" class="" colspan="2">
                                Details to use in API
                              </th>
                            </tr>
                          </thead>
                          <tbody class="pt-1">
                            <tr>
                              <td width="30%">User Key</td>
                              <td class="text-left" width="70%">
                                <button
                                  class="btn btn-success"
                                  @click="updateShowKey()"
                                  v-if="!showKey"
                                >
                                  View
                                </button>
                                <div v-if="showKey">
                                  {{ apiKey }}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>Query Id</td>
                              <td class="text-left">{{ queryId }}</td>
                            </tr>
                            <tr>
                              <td>Report Id</td>
                              <td class="text-left">
                                {{ reportId }}
                              </td>
                            </tr>

                            <tr>
                              <td colspan="2" class="pt-4">
                                <b>cURL Format </b>
                                <sup>
                                  <i
                                    class="icon-info icons font-weight-bold cursor-pointer"
                                    @click="$refs[`info-curlFormat`].show()"
                                  ></i
                                ></sup>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                curl
                                "https://api.finavigator.com/query_data?query_id=<b
                                  >#QUERY ID#</b
                                >&report_id=<b>#REPORT ID#</b>" -H “Accept:
                                application/json” -H “X-Api-Key:
                                <b>#YOUR API KEY#</b>"
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </div>
            <b-modal
              size="xl"
              ref="info-curlFormat"
              class="info-curlFormat"
              title="cURL Format"
              ok-only
            >
              <div>
                <p>
                  When using Windows command line/ Terminal(macOS), format your
                  cURL requests according to the following guidelines:
                </p>
                <h5>Syntax</h5>
                <p class=" alert alert-warning">
                  curl "<b>#API URL#</b>?query_id=<b>#QUERY ID#</b>&report_id=<b
                    >#REPORT ID#</b
                  >" -H “Accept:application/json” -H “X-Api-Key:<b
                    >#YOUR API KEY#</b
                  >"
                </p>
                <h5>Example</h5>
                <p class=" alert alert-warning">
                  curl
                  "https://api.finavigator.com/query_data?query_id=1239&report_id=2769"
                  -H “Accept: application/json” -H “X-Api-Key:
                  4ca2cf9a7f8f92e5cd8b4308652917f0"
                </p>
                <br />
                <h5>URL Parameters</h5>
                <div>
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th width="15%">Name</th>
                        <th width="65%">Description</th>
                        <th width="20%">Example</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#API URL#</td>
                        <td>
                          Specifies which FIN Navigator service is accessed.
                        </td>
                        <td>
                          https://api.finavigator.com/query_data
                        </td>
                      </tr>
                      <tr>
                        <td>#QUERY ID#</td>
                        <td>
                          The numeric FIN Query Id - Currently executed Query Id
                          can be viewed in the Download page. All Query ids can
                          be viewed in the Load Query screen. <br />
                          <b>NOTE:</b> The Query needs to be saved to use in the
                          API Service.
                        </td>
                        <td>506843</td>
                      </tr>
                      <tr>
                        <td>#REPORT ID#</td>
                        <td>
                          The numeric FIN Report Id - Currently executed Report
                          Id can be viewed in the Download page. All Report ids
                          can be viewed in the Load Report screen/ FIN Reporter.
                          <br />
                          <b>NOTE:</b>The Report needs to be saved to use in the
                          API Service.
                        </td>
                        <td>486</td>
                      </tr>
                      <tr>
                        <td>#YOUR API KEY#</td>
                        <td>
                          The alpha-numeric API Key specific to the user. This
                          key is generated once per user when the Development
                          License is purchased.
                        </td>
                        <td>4ca2cf9a7f8f92e5cd8b4308652917f0</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </b-modal>
            <!-- </div> -->
          </div>

          <div class="col-sm-6 px-0" v-if="showSubscriptionOption">
            <div v-if="fetchingPriceData">
              <content-placeholders :rounded="true" class="pt-4">
                <content-placeholders-text
                  :lines="5"
                ></content-placeholders-text>
              </content-placeholders>
            </div>
            <b-card v-else>
              <div class="subscription-card alert alert-info">
                <b-row class="pb-2">
                  <b-col cols="12" class="pl-0">
                    <div class="d-flex">
                      <span class="title-icon px-2">
                        <i class="fa fa-bell fa-3x"></i>
                      </span>
                      <div>
                        <div>
                          <h3 class="mb-0">
                            <b>Subscription Option</b>
                          </h3>
                        </div>
                        <div>Automated Report Delivery</div>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <div>
                  <b-alert
                    show
                    variant="warning"
                    v-if="showUnsavedChangesAlert"
                  >
                    <div class="mb-3">
                      Your changes are not saved. Please save to enable
                      subscription.
                    </div>
                    <div
                      class="row align-items-center mb-3"
                      v-if="queryUnsaved"
                    >
                      <div class="col-sm-4">
                        <b>Query Name</b>
                      </div>
                      <div class="col-sm-8 px-0">
                        <div class="d-flex">
                          <div class="w-100">
                            <b-form-input
                              type="text"
                              name="query_name"
                              class="rounded"
                              placeholder="Enter a Query Name"
                              v-model="queryName"
                              ref="queryName"
                              :class="[
                                $v.queryName.$dirty && $v.queryName.$invalid
                                  ? 'is-invalid'
                                  : '',
                                'form-control rounded'
                              ]"
                            ></b-form-input>
                            <small v-if="!isValidQuery" class="bs-error-color"
                              >query is invalid, please check
                            </small>
                            <b-form-invalid-feedback
                              v-if="
                                $v.queryName.$dirty && $v.queryName.$invalid
                              "
                              >query name required to
                              save</b-form-invalid-feedback
                            >
                          </div>
                          <div>
                            <button
                              type="button"
                              :class="['btn btn-primary rounded ml-2']"
                              @click="saveQueryChanges"
                              :disabled="!isValidQuery"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row align-items-center mb-2"
                      v-if="reportUnsaved"
                    >
                      <div class="col-sm-4">
                        <b>Report Name</b>
                      </div>
                      <div class="col-sm-8 px-0">
                        <div class="d-flex">
                          <div class="w-100">
                            <b-form-input
                              type="text"
                              name="query_name"
                              class="rounded"
                              placeholder="Enter a Report Name"
                              v-model="reportName"
                              ref="reportName"
                              :class="[
                                $v.reportName.$dirty && $v.reportName.$invalid
                                  ? 'is-invalid'
                                  : '',
                                'form-control rounded'
                              ]"
                            ></b-form-input>
                            <b-form-invalid-feedback
                              v-if="
                                $v.reportName.$dirty && $v.reportName.$invalid
                              "
                              >report name required to
                              save</b-form-invalid-feedback
                            >
                          </div>
                          <div>
                            <button
                              type="button"
                              :class="['btn btn-primary rounded ml-2']"
                              @click="saveReportChanges"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-alert>
                  <div class="row pb-3" v-else>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-5 pr-0">
                          <b>Query Name:</b>
                        </div>
                        <div class="col-sm-7 pl-1">
                          {{ queryName || 'NA' }}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="row">
                        <div class="col-sm-5 px-0">
                          <b>Report Name:</b>
                        </div>
                        <div class="col-sm-7 pl-1">
                          {{ reportName || 'NA' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <b-row class="mt-1">
                  <b-col cols="12" class="mb-1">
                    <b-row class="subscription-box px-3">
                      <div class="col-sm-12">
                        <div class="row align-items-center">
                          <div class="col-sm-4">
                            <div class="price-info">
                              Delivery Interval :
                            </div>
                          </div>
                          <div class="col-sm-7 pl-0">
                            <b-form-select
                              v-model="subscriptionInterval"
                              :options="intervalOptions"
                              class="mt-1"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-sm-4">
                            <div class="price-info">
                              Delivery Mode :
                            </div>
                          </div>
                          <div class="col-sm-7 pl-0">
                            <b-form-select
                              v-model="deliveryMode"
                              :options="deliveryOptions"
                              class="mt-2"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="row align-items-center">
                          <div class="col-sm-4">
                            <div class="price-info">
                              Report Format :
                            </div>
                          </div>
                          <div class="col-sm-7 pl-0">
                            <b-form-select
                              v-model="reportFormat"
                              :options="formatOptions"
                              class="mt-2"
                            ></b-form-select>
                          </div>
                        </div>
                      </div>
                    </b-row>
                    <b-row class="px-4 text-center">
                      <b-col
                        cols="12"
                        class="pt-4 mt-1 px-0"
                        style="color: white;"
                      >
                        <button
                          v-b-modal
                          class="btn btn-primary px-4"
                          @click="updateSubscription"
                          :disabled="showUnsavedChangesAlert"
                          v-if="subscription.id"
                        >
                          Update
                        </button>
                        <button
                          v-b-modal
                          class="btn btn-primary fw-btn"
                          @click="subscribeReport"
                          :disabled="showUnsavedChangesAlert"
                          v-else
                        >
                          Subscribe
                        </button>
                        <button
                          v-b-modal
                          class="btn btn-secondary ml-4"
                          @click="deleteSubscription"
                          v-if="subscription.id"
                        >
                          Unsubscribe
                        </button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </b-card>

    <order-details
      :query="finQuery"
      :show="showPurchaseModal"
      :totalRecords="fiCount"
      :amount="totalPrice"
      :encryptedPrice="encryptedPrice"
      :tokens="tokens"
      :tokensBeingUsed="tokensBeingUsed()"
      @closeModal="closeModal"
    >
    </order-details>
    <user-authentication-modal
      :show="showAuthModal"
      @closeAuthModal="closeModal"
      @loginSuccess="loginSuccess"
    ></user-authentication-modal>
  </div>
</template>

<script>
// global
import Vue from 'vue'
import { mapFields } from 'vuex-map-fields'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
// ui components
const OrderDetails = () => import('../components/OrderDetails')
import UserAuthenticationModal from '@/modules/core/components/UserAuthenticationModal'
// api
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'
import subscriptionAPI from '@/api/finapps/subscription'
import userAPI from '@/api/finapps/user'
//general utilities
import _ from 'lodash'
//module utilities
import { validQueryElements } from '../helpers/utilities.js'
//validations
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AQDownload',
  components: {
    OrderDetails,
    UserAuthenticationModal
  },
  data() {
    return {
      showKey: false,
      apiKeyLoaded: false,
      apiKey: '-',
      showPurchaseModal: false,
      showAuthModal: false,
      defaultFields: [
        { key: 'full_name', label: 'Full Name' },
        { key: 'address', label: 'Street Address / Main Office' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'postal_code', label: 'Zip' },
        { key: 'fi_type', label: 'FI Type' },
        { key: 'assets', label: 'Assets' },
        { key: 'branches', label: 'Branches' },
        { key: 'domain', label: 'Domain' },
        {
          key: 'primary_aba_routing_number',
          label: 'Primary ABA routing number'
        },
        { key: 'id', label: 'RSSD ID' },
        { key: 'fdic_certificate_number', label: 'Regulatory ID' }
      ],
      defaultMBCFields: [
        { key: 'full_name', label: 'Full Name' },
        { key: 'address', label: 'Street Address / Main Office' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'postal_code', label: 'Zip' },
        { key: 'fi_type', label: 'FI Type' },
        { key: 'domain', label: 'Domain' },
        { key: 'lei', label: 'LEI' },
        { key: 'id_tax', label: 'Tax ID' },
        { key: 'id', label: 'RSSD ID' },
        { key: 'agency_code', label: 'Agency Code' },
        { key: 'other_lender_code', label: 'Other Lender Code' },
        { key: 'website_url', label: 'Website' },
        { key: 'social_link', label: 'LinkedIn' }
      ],
      intervalOptions: [
        { text: 'Monthly', value: 'monthly' },
        { text: 'Quarterly', value: 'quarterly' },
        { text: 'Annual', value: 'annual' }
      ],
      deliveryOptions: [{ text: 'Application Download', value: 'app_link' }],
      formatOptions: [
        { text: 'CSV', value: 'csv' },
        { text: 'Excel', value: 'xlsx' }
      ]
    }
  },
  created() {
    userAPI.fin_api_key().then(res => {
      this.apiKey = res.key ? res.key : '-'
      this.apiKeyLoaded = true
    })
  },
  computed: {
    ...mapState('AdvancedQuery', {
      tokens: state => state.tokens,
      fiCount: state => state.fiCount,
      finQuery: state => state.finQuery,
      sampleFIs: state => state.sampleFIs,
      keyLabels: state => state.keyLabels,
      baseQuery: state => state.baseQuery,
      totalPrice: state => state.totalPrice,
      fetchingFIs: state => state.fetchingFIs,
      subscription: state => state.subscription,
      minimumPrice: state => state.minimumPrice,
      currentQuery: state => state.currentQuery,
      isValidQuery: state => state.isValidQuery,
      numericFields: state => state.numericFields,
      queryElements: state => state.queryElements,
      selectedQuery: state => state.selectedQuery,
      persistedQuery: state => state.persistedQuery,
      encryptedPrice: state => state.encryptedPrice,
      fetchingPriceData: state => state.fetchingPriceData,
      costPerFIPerElement: state => state.costPerFIPerElement,
      fetchingSubscription: state => state.fetchingSubscription,
      priceWithoutDiscounts: state => state.priceWithoutDiscounts
    }),
    ...mapState('ReportWriter', {
      defaultOptions: state => state.defaultOptions,
      selectedReport: state => state.selectedReport,
      reportElements: state => state.reportElements,
      savedReportElements: state => state.savedReportElements,
      elementsAddedInPricing: state => state.elementsAddedInPricing
    }),
    ...mapGetters('ReportWriter', ['defaultMBCOptions']),
    ...mapGetters('Ability', ['canPurchase', 'isFinApiEnterpriseUser']),
    ...mapGetters('Authentication', ['currentUserEmail']),
    ...mapGetters('AdvancedQuery', [
      'totalPriceInDollars',
      'totalPriceInCents',
      'isMortgageLender'
    ]),
    ...mapFields('AdvancedQuery', {
      queryName: 'selectedQuery.name',
      deliveryMode: 'subscription.delivery_mode',
      subscriptionInterval: 'subscription.schedule',
      reportFormat: 'subscription.delivery_format'
    }),
    ...mapFields('ReportWriter', {
      reportName: 'selectedReport.name'
    }),
    canShowApiSection() {
      return this.isFinApiEnterpriseUser && this.apiKey !== '-'
    },
    userLoggedIn() {
      return this.currentUserEmail
    },
    enablePurchase() {
      return this.canPurchase && this.fiCount
    },
    formattedKeyLabels() {
      let sampleFI = this.sampleFIs[0]

      return Object.entries(this.keyLabels)
        .map(([field, label]) => {
          if (
            sampleFI &&
            sampleFI[field] !== null &&
            typeof sampleFI[field] === 'object'
          ) {
            let sampleData = sampleFI[field]

            if (field === 'vendor_fit') {
              return Object.entries(sampleData)
                .map(([rank]) => {
                  return [
                    {
                      key: `${field}-${rank}`,
                      label: `VendorFit Core Processing Rank ${rank}`,
                      generic_label: label
                    },
                    {
                      key: `${field}-${rank}-score`,
                      label: `VendorFit Core Processing Rank ${rank} Score`,
                      generic_label: `Vendor Rank Score ${rank}`
                    }
                  ]
                })
                .flatten()
            } else {
              return Object.entries(sampleData).map(([period]) => {
                return {
                  key: `${field}-${period}`,
                  label: `${label} (${Vue.filter('toMetricPeriod')(period)})`,
                  generic_label: label
                }
              })
            }
          } else {
            return {
              key: field,
              label: label
            }
          }
        })
        .flatten()
    },
    defaultFieldKeys() {
      return this.isMortgageLender
        ? this.defaultMBCFields.map(df => df.key)
        : this.defaultFields.map(df => df.key)
    },
    tableFields() {
      if (this.sampleFIs && this.sampleFIs.length) {
        let additionalFields = this.formattedKeyLabels.filter(kl => {
          return !this.defaultFieldKeys.includes(kl.key)
        })
        return this.isMortgageLender
          ? [...this.defaultMBCFields, ...additionalFields]
          : [...this.defaultFields, ...additionalFields]
      } else {
        return this.isMortgageLender
          ? this.defaultMBCFields
          : this.defaultFields
      }
    },

    processedSampleFIs() {
      if (this.sampleFIs) {
        this.sampleFIs.forEach(fi => {
          Object.keys(fi).forEach(field => {
            if (fi[field] !== null && typeof fi[field] === 'object') {
              if (field === 'vendor_fit') {
                return Object.entries(fi[field]).forEach(
                  ([rank, fitDetail]) => {
                    fi[`${field}-${rank}`] = fitDetail.name
                    fi[`${field}-${rank}-score`] = fitDetail.confidence
                  }
                )
              } else {
                return Object.entries(fi[field]).forEach(([period, value]) => {
                  fi[`${field}-${period}`] = value
                })
              }
            }
          })
        })
      }
      return this.sampleFIs
    },
    tableData() {
      return this.sampleFIs ? this.processedSampleFIs : []
    },
    elementCount() {
      let defaultElements = this.isMortgageLender
        ? this.defaultMBCOptions
        : this.defaultOptions
      return (
        defaultElements.length +
        this.elementsAddedInPricing.length +
        validQueryElements(this.isMortgageLender).length
      )
    },
    validPurchaseAmount() {
      return this.totalPriceInDollars && this.totalPriceInDollars > 0
    },
    showSubscriptionOption() {
      return (
        this.totalPriceInDollars && parseInt(this.totalPriceInDollars) === 0
      )
    },
    purchaseHintText() {
      return this.validPurchaseAmount ? 'Buy & Download Now' : 'Download'
    },
    purchaseBtnText() {
      return this.validPurchaseAmount ? 'Purchase Now' : 'Download'
    },
    showUnsavedChangesAlert() {
      return this.queryUnsaved || this.reportUnsaved
    },
    queryUnsaved() {
      return (
        !_.isEqual(this.baseQuery, this.currentQuery) &&
        !_.isEqual(this.currentQuery, this.persistedQuery)
      )
    },
    reportUnsaved() {
      return !_.isEqual(this.reportElements, this.savedReportElements)
    },
    queryId() {
      return this.selectedQuery && this.selectedQuery.id
        ? this.selectedQuery.id
        : 'Please save the query to view Query Id.'
    },
    reportId() {
      return this.selectedReport && this.selectedReport.id
        ? this.selectedReport.id
        : 'Please save the report to view Report Id.'
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', ['saveQuery']),
    ...mapActions('ReportWriter', ['saveReport']),
    ...mapMutations('AdvancedQuery', ['setSubscription', 'updatePriceData']),
    tokensBeingUsed() {
      if (this.fiCount === 0) {
        return 0
      }
      if (this.priceWithoutDiscounts == 0 && this.minimumPrice == 0) {
        return 0
      } else if (this.priceWithoutDiscounts < this.minimumPrice) {
        return Math.min(this.minimumPrice, this.tokens)
      } else if (this.priceWithoutDiscounts > this.minimumPrice) {
        return Math.min(this.tokens, this.priceWithoutDiscounts)
      } else {
        return this.tokens
      }
    },
    updateShowKey() {
      this.showKey = true
    },
    purchaseFIData() {
      if (this.userLoggedIn) {
        this.trackQueryDownload()

        advancedQueryAPI
          .downloadFIData(this.finQuery, this.isMortgageLender)
          .then(() => {
            this.showPurchaseModal = true
          })
      } else {
        this.showAuthModal = true
      }
    },
    trackQueryDownload() {
      this.$ahoy.track('download', {
        query_id: this.selectedQuery ? this.selectedQuery.id : '',
        report_id: this.selectedReport ? this.selectedReport.id : '',
        report_type: 'FIN Query Download'
      })
    },
    closeModal() {
      this.showPurchaseModal = false
      this.showAuthModal = false
      this.updatePriceDetails()
    },
    updatePriceDetails() {
      this.$wait.start('updatingPriceDetails')

      return advancedQueryAPI
        .pricing(this.finQuery, this.isMortgageLender)
        .then(res => {
          this.updatePriceData(res)
        })
        .finally(() => {
          this.$wait.end('updatingPriceDetails')
        })
    },
    loginSuccess() {
      this.closeModal()
    },
    determineCellClasses(field) {
      return [
        this.numericFields.includes(field.label) ||
        this.numericFields.includes(field.generic_label)
          ? 'text-right'
          : ''
      ]
    },
    subscribeReport() {
      subscriptionAPI
        .create(
          this.selectedQuery.id,
          this.selectedReport.id,
          this.subscriptionInterval,
          this.reportFormat,
          'fin_query'
        )
        .then(res => {
          this.setSubscription(res.subscription)

          this.$toasted.show(
            'Successfully subscribed for report automated delivery.',
            {
              icon: 'user-circle',
              type: 'success'
            }
          )
        })
    },
    updateSubscription() {
      subscriptionAPI
        .update(this.subscription.id, {
          schedule: this.subscriptionInterval,
          delivery_format: this.reportFormat
        })
        .then(res => {
          this.setSubscription(res.subscription)

          this.$toasted.show('Successfully updated subscription preferences.', {
            icon: 'user-circle',
            type: 'success'
          })
        })
    },
    deleteSubscription() {
      subscriptionAPI.delete(this.subscription.id).then(() => {
        this.setSubscription({
          schedule: 'monthly',
          delivery_mode: 'app_link',
          delivery_format: 'csv'
        })

        this.$toasted.show(
          'Successfully unsubscribed for report automated delivery.',
          {
            icon: 'user-circle',
            type: 'success'
          }
        )
      })
    },
    saveQueryChanges() {
      this.$v.queryName.$touch()

      if (!this.$v.queryName.$invalid) {
        this.saveQuery().then(() => {})
      }
    },
    saveReportChanges() {
      this.$v.reportName.$touch()

      if (!this.$v.reportName.$invalid) {
        this.saveReport().then(() => {})
      }
    }
  },
  validations: {
    queryName: {
      required
    },
    reportName: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.price-box,
.subscription-box {
  table {
    .price-info {
      min-width: 10rem;
    }
  }
}
.full-width {
  width: 100%;
}
.subscription-card {
  .overlay-content {
  }

  .subscription-box {
    .custom-control-inline {
      margin-right: 2rem;
    }
  }
}
</style>

<style lang="scss">
.fin-query-table {
  td {
    padding: 0.5rem;
  }
}
</style>
